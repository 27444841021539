<template>
  <v-container>
    <v-card
      elevation="0"
      style="
        width: 100%;
        height: 100%;
        padding: 1.54cm;
        margin: 0cm;
        page-break-before: always;
      "
    >
      <!-- Empty page is needed to quickly debug print page display -->
    </v-card>
    <template v-for="(item, itemIndex) in items">
      <v-card
        elevation="0"
        style="
          width: 100%;
          height: 100%;
          padding: 1.54cm;
          margin: 0cm;
          page-break-before: always;
        "
        :key="item.genericCode"
      >
        <div style="position: absolute; right: 1cm; top: 1cm" class="pa-2">
          <div class="text-center">
            หน้าที่ {{ itemIndex + 1 }} จาก {{ getTotalItems }}
          </div>
        </div>
        <div class="d-flex flex-column align-center justify-center">
          <div class="font-weight-bold">แบบ รบ.301</div>
          <div>บัญชีรับ-จ่ายยาและเวชภัณฑ์</div>
          <div style="padding-top: 0.25cm">{{ item.genericName }}</div>
          <div style="padding-top: 0.25cm" class="d-flex flex-row">
            <div class="px-4">
              <span class="font-weight-bold">ประเภท : </span
              >{{ getTypeText(item.type) }}
            </div>
            <div class="px-4">
              <span class="font-weight-bold">ขนาดยา : </span>{{ item.dosage }}
            </div>
            <div class="px-4">
              <span class="font-weight-bold">หน่วยนับ : </span>{{ item.unit }}
            </div>
          </div>
          <div style="padding-top: 0.25cm">หน่วยงาน : {{ hosp.org_name }}</div>
        </div>
        <div style="width: 100%; padding-top: 0.2in">
          <table class="tableStyle" style="width: 100%">
            <thead>
              <tr>
                <th class="tableStyle" rowspan="2">วันที่</th>
                <th class="tableStyle" rowspan="2">รายการ</th>
                <th class="tableStyle" rowspan="2">เลขที่เอกสาร</th>
                <th class="tableStyle" rowspan="2">ราคาต่อหน่วย<br>(บาท)</th>
                <th class="tableStyle" colspan="3">จำนวน</th>
                <th class="tableStyle" rowspan="2">มูลค่า<br>(บาท)</th>
              </tr>
              <tr>
                <th class="tableStyle">รับ</th>
                <th class="tableStyle">เบิก</th>
                <th class="tableStyle">คงเหลือ</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="tx in item.transactions">
                <tr style="height: 0.3in" :key="tx.id">
                  <td class="tableStyle pa-1 text-center">
                    {{ new Date(tx.timestamp).toLocaleString("th-TH") }}
                  </td>
                  <td class="tableStyle pa-1 text-center">{{ tx.name }}</td>
                  <td class="tableStyle pa-1 text-center">{{ tx.docNo }}</td>
                  <td class="tableStyle pa-1 text-center">
                    {{ tx.unitPrice.toLocaleString("th-TH") }}
                  </td>
                  <td class="tableStyle pa-1 text-center">
                    {{ tx.quantity.recieve }}
                  </td>
                  <td class="tableStyle pa-1 text-center">
                    {{ tx.quantity.withdraw }}
                  </td>
                  <td class="tableStyle pa-1 text-center">
                    {{ tx.quantity.total }}
                  </td>
                  <td class="tableStyle pa-1 text-center">
                    {{ tx.totalPrice.toLocaleString("th-TH") }}
                  </td>
                </tr>
              </template>
              <template v-for="index in calculateFreeTx(item.transactions)">
                <tr style="height: 0.3in" :key="item.genericCode + '/' + index">
                  <td class="tableStyle pa-1 text-center"></td>
                  <td class="tableStyle pa-1 text-center"></td>
                  <td class="tableStyle pa-1 text-center"></td>
                  <td class="tableStyle pa-1 text-center"></td>
                  <td class="tableStyle pa-1 text-center"></td>
                  <td class="tableStyle pa-1 text-center"></td>
                  <td class="tableStyle pa-1 text-center"></td>
                  <td class="tableStyle pa-1 text-center"></td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div style="padding-top: 1cm" class="d-flex flex-column align-center">
          <div
            style="padding-top: 0cm; width: 100%"
            class="d-flex flex-row justify-space-around"
          >
            <div class="px-4 d-flex flex-column align-center text-center">
              <span
                >................................................................................................</span
              >
              <span
                >(................................................................................................)</span
              >
              <span
                >ตำแหน่ง
                .......................................................................................</span
              >
            </div>
            <div class="px-4 d-flex flex-column align-center text-center">
              <span
                >................................................................................................</span
              >
              <span
                >(................................................................................................)</span
              >
              <span
                >ตำแหน่ง
                .......................................................................................</span
              >
            </div>
          </div>
          <br /><br />
          <div
            style="padding-top: 0cm; width: 100%"
            class="d-flex flex-row justify-space-around"
          >
            <div class="px-4 d-flex flex-column align-center text-center">
              <span
                >................................................................................................</span
              >
              <span
                >(................................................................................................)</span
              >
              <span
                >ตำแหน่ง
                .......................................................................................</span
              >
            </div>
            <div class="px-4 d-flex flex-column align-center text-center">
              <span
                >................................................................................................</span
              >
              <span
                >(................................................................................................)</span
              >
              <span
                >ตำแหน่ง
                .......................................................................................</span
              >
            </div>
          </div>
        </div>
      </v-card>
    </template>
  </v-container>
</template>
<script>
import axios from "axios";
// const url = "http://localhost:8910/api";
// const url = "http://anantasolutions.co/flagship/api"
const url = "http://164.115.22.214/api";
export default {
  data: () => {
    return {
      items: [],
      hosp: [],
    };
  },
  methods: {
    applyRouterLink(name) {
      this.$router.push({ name });
    },
    getTypeText(type) {
      if (type == "med") return "เวชภัณฑ์ยา";
      else if (type == "nonmed") return "เวชภัณฑ์ที่มิใช่ยา";
      else if (type == "herb") return "สมุนไพร";
      else return "อื่นๆ";
    },
    calculateFreeTx(transactions) {
      return transactions.length;
    },
    initialize() {
      // init here
      // get hosp detail
    //   axios
    //     .post(url + "/organizations/lookup", {
    //       org_id: this.$cookies.get("user").hosp_code,
    //     })
    //     .then((res) => {
    //       this.hosp = res.data;
    //       console.log(this.hosp);
    //     });
        this.hosp = {
            'org_name': this.$cookies.get("user").hosp_name
        }
            
        
    },
    getTransactions() {
      this.items = [];
      var preitems;
      // console.log('getTransactions')
      axios
        .post(url + "/pcoc/getRB301", {
          hosp_code: this.$cookies.get("user").hosp_code,
          selected: this.$route.params.selected,
        })
        .then((res) => {
          preitems = res.data;
          // console.log(preitems)
          preitems.forEach((element) => {
            var item = {
              genericCode: element.genericCode,
              genericName: element.genericName,
              type: element.type,
              dosage: element.dosage,
              unit: element.unit,
              transactions: [],
            };
            var subTotal = 0
            var netPrice = 0
            element.logs.forEach((el) => {
              // calculate subTotal and netPrice
              subTotal += el.quantity.receive
              subTotal -= el.quantity.withdraw
              netPrice += (el.quantity.receive - el.quantity.withdraw) * el.unitPrice
              var log = {
                id: el.id,
                timestamp: el.timestamp,
                name: el.name,
                docNo: "-",
                unitPrice: el.unitPrice,
                quantity: {
                  recieve: el.quantity.receive,
                  withdraw: el.quantity.withdraw,
                  total: subTotal,
                },
                totalPrice: netPrice,
              };
              item.transactions.push(log);
            });
            if (item.transactions.length > 0) {
              this.items.push(item);
            }
          });
        });
    },
  },
  computed: {
    getTotalItems() {
      return this.items.length;
    },
  },
  mounted() {
    this.initialize();
    this.getTransactions();
  },
};
</script>